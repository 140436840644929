import React from "react";
import TypeWriter from "../Components/TypeWriter";
import PageTemplate from "../Components/PageTemplate";
import BuyMeACoffee from "../Components/Buttons/BuyMeACoffee";

const Support = () => {
  const supportContent = () => {
    return (
      <div>
        <div className="title">
          <TypeWriter text={"SUPPORT US!"} length={"short"} />
        </div>
        <div className="container is-fluid has-text-centered">
          <p className="block is-size-5-desktop is-size-6-mobile">
            Hi! Thank you SO much for using{" "}
            <strong>Birthday Melody Match</strong>! We hope you're enjoying it!
            We put in a LOT of time and effort coding and designing{" "}
            <strong>Birthday Melody Match</strong> to give you the best melody
            making experience we could. We have a lot planned to make{" "}
            <strong>Birthday Melody Match</strong> even MORE awesome, and this
            is where <strong>YOU</strong> come in! :) With only one developer,
            and one behind-the-scenes musical genius, to keep things running{" "}
            <strong>ad-free</strong>, we depend on the support from{" "}
            <strong>YOU</strong> - our melody making friends! Any support is
            appreciated! Click the button below to buy us a coffee, and make
            sure you share <strong>Birthday Melody Match</strong> with someone
            awesome! Tag us on Instagram and Twitter, and show us your awesome
            melodies! Stay tuned for some <strong>cool announcements</strong>{" "}
            coming in the near future! Happy melody making!
          </p>
          <BuyMeACoffee />
        </div>
      </div>
    );
  };

  return <PageTemplate content={supportContent()} currentPage={"support"} />;
};

export default Support;
