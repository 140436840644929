import { React, useState } from "react";
import SoundFontPlayer from "soundfont-player";
import RoundedIconButtonNoNav from "../Buttons/RoundedIconButtonNoNav";
import ShareModal from "../Modal/ShareModal";
import {
  getFirstNote,
  getSecondFourthSeventhEighthNote,
  getThirdNote,
  getFifthNote,
  getSixthNote,
} from "../../functions/twoUserMusic";
import RoundedIconButton from "../Buttons/RoundedIconButton";

const PlayMusicOne = ({ instrumentName }) => {
  let ac = new AudioContext();
  const mediaStreamDestination = ac.createMediaStreamDestination();
  const mediaRecorder = new MediaRecorder(mediaStreamDestination.stream);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalActive, setIsModalActive] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const chunks = [];
  let url;

  let pathname = window.location.pathname;
  let new_pathname = pathname.split("-");
  new_pathname.shift();
  let firstName = new_pathname[0].toLowerCase();
  let birthyear = new_pathname[1];
  let birthmonth = new_pathname[2];
  let birthdate = new_pathname[3];
  let gender = new_pathname[4];

  // getting birth year digits
  let firstDigitBirthYear = birthyear[0];
  let secondDigitBirthYear = birthyear[1];
  let thirdDigitBirthYear = birthyear[2];
  let fourthDigitBirthYear = birthyear[3];

  // getting month digits
  let firstMonthDigit = birthmonth[0];
  let secondMonthDigit = birthmonth[1];

  // getting date values
  let firstDateDigit = birthdate[0];
  let secondDateDigit = birthdate[1];

  const showDownloadButton = ({ instrumentName }) => {
    return (
      <a
        href={blobURL}
        className="link"
        download={`${firstName}_${instrumentName}_melody.aac`}
      >
        <button
          className="button is-responsive is-medium is-light is-rounded"
          disabled={isButtonDisabled}
        >
          <span className="icon">
            <i className={`fas fa-download`}></i>
          </span>
          <span>SAVE</span>
        </button>
      </a>
    );
  };

  let firstLength,
    secondLength,
    thirdLength,
    fourthLength,
    fifthLength,
    sixthLength,
    seventhLength,
    eighthLength,
    firstTime,
    secondTime,
    thirdTime,
    fourthTime,
    fifthTime,
    sixthTime,
    seventhTime,
    eighthTime;

  // const checkIsChristmas = () => {
  //   if (birthdate === "25" && birthmonth === "12" && birthyear === "2023") {
  //     return true;
  //   }
  //   return false;
  // };

  const noteLength = () => {
    if (gender === "Female") {
      firstLength = secondLength = fifthLength = sixthLength = secondTime = 1;
      seventhLength = thirdTime = 2;
      thirdLength = 1.5;
      fourthLength = 0.5;
      eighthLength = 3;
      firstTime = 0;
      fourthTime = 3.5;
      fifthTime = 4;
      sixthTime = 5;
      seventhTime = 6;
      eighthTime = 8;
    } else if (gender === "Male") {
      firstLength = secondTime = 1.5;
      secondLength = 0.5;
      thirdLength = fourthLength = sixthLength = 1;
      fifthLength = 0.5;
      seventhLength = 2.5;
      eighthLength = fourthTime = 3;
      firstTime = 0;
      thirdTime = 2;
      fifthTime = 4;
      sixthTime = 4.5;
      seventhTime = 5.5;
      eighthTime = 8;
    }
  };

  return (
    <div>
      <button
        className={`button is-light is-fullwidth is-responsive is-rounded 
                ${isLoading && "is-loading"}
                `}
        onClick={() => {
          ac.resume();
          mediaRecorder.start(100);
          setIsLoading(true);
          setIsButtonDisabled(true);
          noteLength();

          SoundFontPlayer.instrument(ac, `${instrumentName}`).then(function (
            instrument
          ) {
            instrument.connect(mediaStreamDestination);
            instrument.schedule(ac.currentTime, [
              {
                time: firstTime,
                duration: firstLength,
                note: getFirstNote(firstDateDigit, gender),
              },
              {
                time: secondTime,
                duration: secondLength,
                note: getSecondFourthSeventhEighthNote(secondDateDigit),
              },
              {
                time: thirdTime,
                duration: thirdLength,
                note: getThirdNote(firstMonthDigit, gender),
              },
              {
                time: fourthTime,
                duration: fourthLength,
                note: getSecondFourthSeventhEighthNote(secondMonthDigit),
              },
              {
                time: fifthTime,
                duration: fifthLength,
                note: getFifthNote(firstDigitBirthYear, gender),
              },
              {
                time: sixthTime,
                duration: sixthLength,
                note: getSixthNote(secondDigitBirthYear, gender),
              },
              {
                time: seventhTime,
                duration: seventhLength,
                note: getSecondFourthSeventhEighthNote(thirdDigitBirthYear),
              },
              {
                time: eighthTime,
                duration: eighthLength,
                note: getSecondFourthSeventhEighthNote(fourthDigitBirthYear),
              },
            ]);

            mediaRecorder.addEventListener("dataavailable", (e) => {
              chunks.push(e.data);
            });

            setTimeout(() => {
              if (mediaRecorder.state === "inactive") return;
              mediaRecorder.stop();
              url = URL.createObjectURL(new Blob(chunks));
              setBlobURL(url);
              setIsLoading(false);
              setIsButtonDisabled(false);
            }, 11000);
          });
        }}
      >
        <span className="icon">
          <i className={`fas fa-solid fa-play`}></i>
        </span>
        <span>PLAY MELODY</span>
      </button>
      &nbsp;
      <div className="buttons is-centered is-responsive">
        <RoundedIconButtonNoNav
          icon={"fa-share-nodes"}
          text={"SHARE"}
          onClickFunction={() => {
            setIsModalActive(true);
          }}
          isDisabledBool={isButtonDisabled}
        />
        {showDownloadButton({ instrumentName })}
        <RoundedIconButton
          icon="fa-solid fa-arrows-rotate"
          text="New Melody"
          whereToNavigate={"generate"}
          isDisabledBool={isButtonDisabled}
        />
      </div>
      <div className="buttons is-centered is-responsive">
        <RoundedIconButton
          icon="fa-solid fa-heart"
          text="Support Us"
          whereToNavigate={"support"}
        />
        {/* <RoundedIconButton
          icon="fa-solid fa-envelope"
          text="Send a Card"
          whereToNavigate={"cards"}
        /> */}
      </div>
      {isModalActive && (
        <ShareModal deleteOnClick={() => setIsModalActive(false)} />
      )}
    </div>
  );
};

export default PlayMusicOne;
