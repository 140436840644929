import { React, useState } from "react";
import PageTemplate from "../../Components/PageTemplate";
import RoundedIconButton from "../../Components/Buttons/RoundedIconButton";
import RoundedIconButtonNoNav from "../../Components/Buttons/RoundedIconButtonNoNav";
import ShareModal from "../../Components/Modal/ShareModal";
import PlayMusicTwo from "../../Components/PlayMusic/PlayMusicTwo";
import InstrumentDropdown from "../../Components/InstrumentDropdown";
import TypeWriter from "../../Components/TypeWriter";
import CompatibilityModal from "../../Components/Modal/CompatibilityModal";
// import ValentineModal from "../../Components/Modal/ValentineModal";

const MelodyTwoUsers = () => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [isCompatibilityActive, setIsCompatibilityActive] = useState(false);
  // const [isValentinesActive, setIsValentinesActive] = useState(false);
  const [instrumentValue, setInstrumentValue] = useState(
    "acoustic_grand_piano"
  );

  let pathname = window.location.pathname;
  let new_pathname = pathname.split("-");
  new_pathname.shift();
  let firstName_user1 = new_pathname[0];
  let birthyear_user1 = new_pathname[1];
  let birthmonth_user1 = new_pathname[2];
  let birthdate_user1 = new_pathname[3];
  let gender_user1 = new_pathname[4];
  let firstName_user2 = new_pathname[5];
  let birthyear_user2 = new_pathname[6];
  let birthmonth_user2 = new_pathname[7];
  let birthdate_user2 = new_pathname[8];
  let gender_user2 = new_pathname[9];

  const melodyTwoUserContent = () => {
    return (
      <div className="has-text-centered">
        <div className="title">
          <TypeWriter text={"RESULTS"} length={"short"} />
        </div>
        <p className="block">
          Hey{" "}
          <strong>
            {firstName_user1} & {firstName_user2}
          </strong>
          ! We've gathered your information!
        </p>
        <p className="block">
          <strong>{firstName_user1}</strong>, your <strong>birthdate</strong> is{" "}
          {birthyear_user1}-{birthmonth_user1}-{birthdate_user1} and your{" "}
          <strong>gender</strong> is {gender_user1.toLowerCase()}.
        </p>
        <p className="block">
          <strong>{firstName_user2}</strong>, your <strong>birthdate</strong> is{" "}
          {birthyear_user2}-{birthmonth_user2}-{birthdate_user2} and your{" "}
          <strong>gender</strong> is {gender_user2.toLowerCase()}.
        </p>
        <p className="block">
          If on <strong>mobile</strong>, turn <strong>off</strong> silent mode
          to hear your melody!
        </p>
        <p className="block">
          <InstrumentDropdown
            onChangeFunction={(e) => setInstrumentValue(e.target.value)}
          />
        </p>

        <p className="block">
          <PlayMusicTwo instrumentName={instrumentValue} />
        </p>

        <div className="buttons is-centered is-medium">
          {isModalActive && (
            <ShareModal deleteOnClick={() => setIsModalActive(false)} />
          )}
          {isCompatibilityActive && (
            <CompatibilityModal
              deleteOnClick={() => setIsCompatibilityActive(false)}
            />
          )}
          <RoundedIconButtonNoNav
            icon={"fa-bars-progress"}
            text={"COMPATIBILITY"}
            onClickFunction={() => {
              setIsCompatibilityActive(true);
            }}
          />
          <RoundedIconButton
            icon="fa-solid fa-heart"
            text="Support Us"
            whereToNavigate={"support"}
          />
          {/* <RoundedIconButton
            icon="fa-solid fa-envelope"
            text="Send a Card"
            whereToNavigate={"cards"}
          /> */}
          {/* <RoundedIconButtonNoNav
            icon={"fa-solid fa-envelope"}
            text={"Send a Valentine!"}
            onClickFunction={() => {
              setIsValentinesActive(true);
              console.log("activate modal");
            }}
          /> */}
          {/* {isValentinesActive && (
            <ValentineModal
              deleteOnClick={() => setIsValentinesActive(false)}
            />
          )} */}
        </div>
      </div>
    );
  };

  return <PageTemplate content={melodyTwoUserContent()} currentPage={""} />;
};

export default MelodyTwoUsers;
